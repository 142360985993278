import React from "react";
import {
  MDBFooter,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
} from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import logo from "../images/tenzsoftlogo.png";
import "../styles/Footer.css";

export default function App() {
  return (
    <MDBFooter bgColor="warning" className="text-center text-lg-start text-muted">
      <section className=" container d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
        <div className="me-5 d-none d-lg-block">
          <span className="social-text text-black fw-bold">
            Get connected with us on social networks:
          </span>
        </div>

        <div>
         <a href="https://www.facebook.com/tenzsoft">
            <MDBIcon fab icon="facebook-f" className="text-black me-4"/>
          </a>
         
         <a href="https://www.instagram.com/tenzsoft/">
            <MDBIcon fab icon="instagram" className="text-black me-4"/>
          </a>
          
          <a href="https://www.linkedin.com/company/tenzsoft/?originalSubdomain=pk">
            <MDBIcon fab icon="linkedin" className="text-black"/>
          </a>
        </div>
      </section>

      <section className="bg-dark text-white">
        <MDBContainer className="text-center text-md-start mt-1">
          <MDBRow className="">
            <MDBCol md="3" lg="4" xl="3" className="mx-auto mt-0">
              <img src={logo} alt="" height={160} width={200} />
              <p className="">
                "Tenz Soft" specializes in technological and IT-related
                services such as Webdevelopment , Application development,
                and   software development</p>
            </MDBCol>

            <MDBCol md="2" lg="2" xl="2" className="mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4 text-warning">
                <MDBIcon icon="desktop" className="me-2 text-warning" />
                IT Serivces
              </h6>
              <p>
                <Link to="/web" className="text-reset">
                  Web Development
                </Link>
              </p>
              <p>
                <Link to="/app" className="text-reset">
                  App Development
                </Link>
              </p>
              <p>
                <Link to="/digital" className="text-reset">
                  Digital Marketing
                </Link>
              </p>
              <p>
                <Link to="/cloud" className="text-reset">
                  Cloud Computing
                </Link>
              </p>
              <p>
                <Link to="/seo" className="text-reset">
                  Google SEO
                </Link>
              </p>
              <p>
                <Link to="/software" className="text-reset">
                  Software Development
                </Link>
              </p>
            </MDBCol>

            <MDBCol md="3" lg="2" xl="2" className="mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4 text-warning">
                <MDBIcon icon="home" className="me-2 text-warning" />
                Quick links
              </h6>
              <p>
                <Link to="/" className="text-reset">
                  Home
                </Link>
              </p>
              <p>
                <Link to="/about" className="text-reset">
                  Why Tenz Soft
                </Link>
              </p>
              <p>
                <Link to="/services" className="text-reset">
                  Services
                </Link>
              </p>
              <p>
                <Link to="/franchise" className="text-reset">
                  Offices
                </Link>
              </p>
              <p>
                <Link to="/projects" className="text-reset">
                  Our Projects
                </Link>
              </p>
              <p>
                <Link to="/about" className="text-reset">
                  About
                </Link>
              </p>
            </MDBCol>

            <MDBCol md="4" lg="3" xl="3" className="mx-auto mb-md-0 mb-4">
              <h6 className="text-uppercase fw-bold mb-4 text-warning">
                <MDBIcon icon="headset" className="me-2 text-warning" />
                Contact
              </h6>
              <p>
                <MDBIcon icon="home" className="me-2 text-warning" />
                3110 Kingston Rd, Scarborough, ON M1M 1P2, Canada
              </p>
              <p>
                <MDBIcon icon="envelope" className="me-3 text-warning" />
                info@tenzsoft.ca
                
              </p>
              <p>
                <MDBIcon icon="phone" className="me-3 text-warning" /> 7059054083
              </p>
              <p>
                <MDBIcon icon="phone" className="me-3 text-warning" /> +92300 4843092
              </p>
              <p>
                <MDBIcon icon="phone" className="me-3 text-warning" /> 055-2110887
              </p>
            
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <div
        className="text-center p-4 text-black"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
      >
        © 2024 Copyright:
        <a className="text-reset fw-bold " href="https://tenzsoft.com/">
          Designed by Tenz Soft
        </a>
      </div>
    </MDBFooter>
  );
}
