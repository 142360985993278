import React, { useRef } from "react";
import Navbar from "../components/NavBar";
import "../styles/contact.css";
import Footer from "../components/Footer";
import emailjs from "@emailjs/browser";
// import Map from "../components/Map"
import {useLocation} from "react-router-dom"
import { useEffect } from 'react'
const Contact = () => {
  const {pathname}=useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_lvkngyw",
        "template_exomw7w",
        form.current,
        "No_WmAYv_qDyALd1u"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  return (
    <div>
      <Navbar />
      {/* <Map/> */}
      {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15282225.79979123!2d73.7250245393691!3d20.750301298393563!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30635ff06b92b791%3A0xd78c4fa1854213a6!2sIndia!5e0!3m2!1sen!2sin!4v1587818542745!5m2!1sen!2sin" width="300" height="300" ></iframe> */}

      <section id="contact" class="contact">
        <div class="container" data-aos="fade-up">
          <header class="section-header">
            <p class="text-warning">Contact Us</p>
          </header>

          <div class="row gy-4">
            <div class="col-lg-6">
              <div class="row gy-4">
                <div class="col-md-6">
                  <div class="info-box">
                    <i class="bi bi-geo-alt text-warning"></i>
                    <h3 class="text-success">Address</h3>
                    <p>
                      Tenz Soft,
                      <br />
                      3110 Kingston Rd, Scarborough, ON M1M 1P2, Canada
                    </p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="info-box">
                    <i class="bi bi-telephone text-warning"></i>
                    <h3 class="text-success">Call Us</h3>
                    <p>7059054083</p>
                    <p>+92300 4843092</p>
                    <p>055-2110887</p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="info-box">
                    <i class="bi bi-envelope text-warning"></i>
                    <h3 class="text-success">Email Us</h3>
                    <p>
                      info@tenzsoft.ca
                      <br />
                    </p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="info-box">
                    <i class="bi bi-clock text-warning"></i>
                    <h3 class="text-success">Open Hours</h3>
                    <p>
                      Monday - Saturday
                      <br />
                      9:00AM - 06:00PM
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6">
              <form ref={form} onSubmit={sendEmail} class="php-email-form">
                <div class="row gy-4">
                  <div class="col-md-6">
                    <input
                      type="text"
                      name="name"
                      class="form-control"
                      placeholder="Your Name"
                      required
                    />
                  </div>

                  <div class="col-md-6 ">
                    <input
                      type="email"
                      class="form-control"
                      name="email"
                      placeholder="Your Email"
                      required
                    />
                  </div>

                  <div class="col-md-12">
                    <input
                      type="text"
                      class="form-control"
                      name="project"
                      placeholder="Project Type"
                      required
                    />
                  </div>

                  <div class="col-md-12">
                    <textarea
                      class="form-control"
                      name="details"
                      rows="6"
                      placeholder="Tell us more about your project...."
                      required
                    ></textarea>
                  </div>

                  <div class="col-md-12 text-center">
                    <div class="loading">Loading</div>
                    <div class="error-message"></div>
                    <div class="sent-message">
                      Your message has been sent. Thank you!
                    </div>

                    <button type="submit" value="send" class="bg-success">
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div>

        </div>
      </section>
      <Footer />
    </div>
  );
};
export default Contact;


